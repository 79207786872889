'use client';

import type { FC, PropsWithChildren } from 'react';
import { ViewportProvider as ViewPortProviderSrc } from '@sbt-web/hooks';

const ViewPortProvider: FC<PropsWithChildren> = ({ children }) => (
  <ViewPortProviderSrc defaultType="mobile">{children}</ViewPortProviderSrc>
);

export default ViewPortProvider;
