// Network
export const WEB_API_CHANNEL = 'web';

// Google Tag Manager
export const GTM_URL = 'https://www.googletagmanager.com/gtm.js?id=GTM-TWPJW6H';
export const GTM_STAGE_SUFFIX =
  '&gtm_auth=KOPoJMwiqaNuTvoLKOs7hA&gtm_preview=env-5';

// Key of the item in local storage with the recent searches
export const RECENT_SEARCHES_KEY = 'recent_autocomplete_searches';
