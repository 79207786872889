'use client';

import { getPublicUser, type PublicUser } from '@sbt-web/auth';
import { sendEventToGTM } from '@sbt-web/tracking';
import { initDidomi } from '@sbt-web/didomi';
import React, { useContext, useEffect } from 'react';
import { initAppsflyer } from '../../helpers/appsflyer';
import { getTrackingInstance } from '../../helpers/PulseTracking';
import { loadGTM } from './gtm';
import { DataLayerReadyContext } from './DataLayerContext';

const addDataLayerInfo = function (userDetails: PublicUser | null) {
  if (window.subito?.dataLayer) {
    window.subito.dataLayer.push({
      page: {
        name: document.title,
        page_type: 'homepage',
        getPageType: () => 'homepage',
      },
    });
    window.subito.dataLayer.push({
      env: {
        name: process.env.NEXT_PUBLIC_INTERNAL_ENVIRONMENT,
        cachebuster: '',
        tracking: {},
      },
    });
    if (userDetails) {
      window.subito.dataLayer.push({
        user: {
          id: userDetails.id,
          email: userDetails.email,
        },
      });
    }
  } else {
    console.warn('datalayer not defined');
  }
  sendEventToGTM('subito_load');
};

export const SubitoScripts = () => {
  const { setDataLayerReady } = useContext(DataLayerReadyContext);

  useEffect(() => {
    const userDetails = getPublicUser();
    addDataLayerInfo(userDetails);
    initDidomi(getTrackingInstance(), 'homepage');
    setDataLayerReady(true);
    window.didomiOnReady.push(initAppsflyer);

    loadGTM();
  }, [setDataLayerReady]);

  return (
    <>
      <script
        dangerouslySetInnerHTML={{
          __html: 'window.subito = {dataLayer: []};',
        }}
      />
      <script
        src={`${process.env.NEXT_PUBLIC_ASSETS_BASE}/static/js/data-layer.js`}
        crossOrigin="anonymous"
      />
    </>
  );
};
